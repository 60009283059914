<template>
  <div>
    <v-card-text class="py-6">
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <v-text-field
            dense
            v-model="procesoEvaluado"
            label="Proceso Evaluado"
            clearable
            clear-icon="mdi-close"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <v-text-field
            dense
            v-model="nombreIndicador"
            label="Nombre del Indicador"
            clearable
            clear-icon="mdi-close"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <v-text-field dense v-model="queBusca" label="Que se busca" clearable clear-icon="mdi-close"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" class="py-0">
          <v-text-field
            dense
            v-model="unidadMedida"
            label="Unidad de Medida"
            clearable
            clear-icon="mdi-close"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="8" class="py-0">
          <v-text-field
            dense
            v-model="formaCalculo"
            label="Formula de Cálculo"
            clearable
            clear-icon="mdi-close"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="py-0 pt-2">
          <v-textarea
            outlined
            dense
            v-model="detalleFormula"
            label="Detalle de la fórmula"
            clearable
            clear-icon="mdi-close"
          ></v-textarea>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <h5 class="body-1 g">Consideraciones de Gestión</h5>
          <v-text-field dense class="ma-0 pa-0" v-model="consideracion1" clearable clear-icon="mdi-close">
            <template v-slot:prepend>
              <v-icon color="green">
                mdi-circle
              </v-icon>
            </template>
          </v-text-field>
          <v-text-field dense class="ma-0 pa-0" v-model="consideracion2" clearable clear-icon="mdi-close">
            <template v-slot:prepend>
              <v-icon color="amber">
                mdi-circle
              </v-icon>
            </template>
          </v-text-field>
          <v-text-field dense class="ma-0 pa-0" v-model="consideracion3" clearable clear-icon="mdi-close">
            <template v-slot:prepend>
              <v-icon color="red">
                mdi-circle
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <v-textarea
            outlined
            dense
            v-model="fuenteDatos"
            label="Fuente de Datos"
            clearable
            clear-icon="mdi-close"
          ></v-textarea>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <v-textarea
            outlined
            dense
            v-model="datosHistoricos"
            label="Datos Históricos"
            clearable
            clear-icon="mdi-close"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" class="py-0">
          <v-text-field
            dense
            v-model="metaPropuesta"
            type="number"
            label="Meta Propuesta del Indicador"
            clearable
            clear-icon="mdi-close"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="8" class="py-0">
          <v-autocomplete
            dense
            v-model="responsablesMetas"
            :search-input.sync="searchMetas"
            :items="itemsParticipantes"
            item-text="name"
            item-value="code"
            no-data-text="No se encontraron datos"
            label="Responsables de las Metas"
            placeholder="Responsables de las Metas"
            prepend-icon="mdi-database-search"
            return-object
          >
            <template v-slot:selection="data">
              <v-chip small> {{ data.item.name }} </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  <v-list-item-subtitle>
                    {{ `${data.item.dni} - ${data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS'}` }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <v-select dense v-model="frecuenciaMedicion" :items="frecuencias" label="Frecuendia de Medición"></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <v-autocomplete
            dense
            v-model="responsableMedicion"
            :search-input.sync="searchMedicion"
            :items="itemsParticipantes"
            item-text="name"
            item-value="code"
            label="Responsable de Medición"
            no-data-text="No se encontraron datos"
            placeholder="Responsable de Medición"
            prepend-icon="mdi-database-search"
            return-object
          >
            <template v-slot:selection="data">
              <v-chip small> {{ data.item.name }} </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  <v-list-item-subtitle>
                    {{ `${data.item.dni} - ${data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS'}` }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <v-autocomplete
            dense
            v-model="responsableEvalucion"
            :search-input.sync="searchEvaluacion"
            :items="itemsParticipantes"
            item-text="name"
            item-value="code"
            label="Responsable de Evaluación"
            no-data-text="No se encontraron datos"
            placeholder="Responsable de Evaluación"
            prepend-icon="mdi-database-search"
            return-object
          >
            <template v-slot:selection="data">
              <v-chip small> {{ data.item.name }} </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  <v-list-item-subtitle>
                    {{ `${data.item.dni} - ${data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS'}` }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <v-textarea
            outlined
            dense
            v-model="observaciones"
            label="Observaciones"
            clearable
            clear-icon="mdi-close"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" class="py-0">
          <v-text-field
            dense
            v-model="elaboro"
            label="Elaboró"
            placeholder="Elaboró"
            prepend-icon="mdi-database-search"
            return-object
            disabled
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <v-autocomplete
            dense
            v-model="reviso"
            :search-input.sync="searchReviso"
            :items="itemsParticipantes"
            item-text="name"
            item-value="code"
            label="Revisó"
            placeholder="Revisó"
            no-data-text="No se encontraron datos"
            prepend-icon="mdi-database-search"
            return-object
          >
            <template v-slot:selection="data">
              <v-chip small> {{ data.item.name }} </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  <v-list-item-subtitle>
                    {{ `${data.item.dni} - ${data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS'}` }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <v-autocomplete
            dense
            v-model="aprobo"
            :search-input.sync="searchAprobo"
            :items="itemsParticipantes"
            item-text="name"
            item-value="code "
            label="Aprobó"
            placeholder="Aprobó"
            no-data-text="No se encontraron datos"
            prepend-icon="mdi-database-search"
            return-object
          >
            <template v-slot:selection="data">
              <v-chip small> {{ data.item.name }} </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  <v-list-item-subtitle>
                    {{ `${data.item.dni} - ${data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS'}` }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" @click="cancelar()">Cerrar</v-btn>
      <v-btn v-auth-acl="'gh-ind-hoj_norm-insert'" color="info" submit @click="guardar()">Guardar</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import FichaTecnicaService from '../services/FichaTecnicaService';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { decryptAES, decryptData } from '@/components4x/utils/utils4x';
export default {
  name: 'FichaTecnicaFormulario',
  data: () => ({
    fichaTecnicaService: null,
    queBusca: '',
    search: '',
    searchMetas: '',
    searchMedicion: '',
    searchEvaluacion: '',
    searchReviso: '',
    searchAprobo: '',
    procesoEvaluado: '',
    nombreIndicador: '',
    unidadMedida: '',
    formaCalculo: '',
    detalleFormula: '',
    consideracion1: '',
    consideracion2: '',
    consideracion3: '',
    fuenteDatos: '',
    datosHistoricos: '',
    metaPropuesta: 0,
    /* responsablesMetas: [], */
    responsablesMetas: '',
    frecuenciaMedicion: '',
    frecuenciaEvaluacion: '',
    responsableMedicion: '',
    responsableEvalucion: '',
    observaciones: '',
    elaboro: '',
    reviso: '',
    aprobo: '',
    itemsParticipantes: [],
    frecuencias: [
      { text: 'DIARIO', value: 'DIARIO' },
      { text: 'SEMANAL', value: 'SEMANAL' },
      { text: 'QUINCENAL', value: 'QUINCENAL' },
      { text: 'MENSUAL', value: 'MENSUAL' },
      { text: 'BIMESTRAL', value: 'BIMESTRAL' },
      { text: 'TRIMESTRAL', value: 'TRIMESTRAL' },
      { text: 'SEMESTRAL', value: 'SEMESTRAL' },
      { text: 'ANUAL', value: 'ANUAL' }
    ]
  }),
  watch: {},
  methods: {
    async cargarResponsables() {
      this.showLoading = true;
      const responsable = localStorage.getItem('lrd5qwlr674');
      const decryptId = decryptData(responsable);
      const res = await this.fichaTecnicaService.get().execResource('responsables', {
        idempresa: decryptAES(localStorage.getItem('emp'))
      });

      if (res.length > 0) {
        res.forEach((el) => {
          if (el.idsubarea != '')
            this.itemsParticipantes.push({
              id: uuidv4(),
              code: el.idresponsable,
              dni: el.idtrabajador,
              name: el.nombresall,
              idsubarea: el.idsubarea,
              email: el.email,
              leader: false,
              new: false,
              object: {}
            });
        });
        this.itemsParticipantes.forEach((el) => {
          if (el.code == decryptId) {
            this.elaboro = el.name;
          }
        });
      } else {
        this.itemsParticipantes = [];
      }

      this.showLoading = false;
    },
    cancelar() {
      this.$router.push('/indicadores/matriz');
    },
    async guardar() {
      if (this.procesoEvaluado.length <= 0) {
        await this.alertDialog('warning', 'Ingrese un proceso Evaluado');
        return;
      }
      if (this.nombreIndicador.length <= 0) {
        await this.alertDialog('warning', 'Ingrese un nombre de indicador');
        return;
      }
      if (this.queBusca.length <= 0) {
        await this.alertDialog('warning', 'Ingrese que se busca');
        return;
      }
      if (this.unidadMedida.length <= 0) {
        await this.alertDialog('warning', 'Ingrese una unidad de medida');
        return;
      }
      if (this.formaCalculo.length <= 0) {
        await this.alertDialog('warning', 'Ingrese una forma de cálculo');
        return;
      }
      if (this.detalleFormula.length <= 0) {
        await this.alertDialog('warning', 'Ingrese un detalle de la fórmula');
        return;
      }
      if (this.consideracion1.length <= 0) {
        await this.alertDialog('warning', 'Ingrese una consideracion');
        return;
      }
      if (this.consideracion2.length <= 0) {
        await this.alertDialog('warning', 'Ingrese una consideracion');
        return;
      }
      if (this.consideracion3.length <= 0) {
        await this.alertDialog('warning', 'Ingrese una consideracion');
        return;
      }
      if (this.fuenteDatos.length <= 0) {
        await this.alertDialog('warning', 'Ingrese una fuente de datos');
        return;
      }
      if (this.datosHistoricos.length <= 0) {
        await this.alertDialog('warning', 'Ingrese datos históricos');
        return;
      }
      if (this.metaPropuesta.length <= 0) {
        await this.alertDialog('warning', 'Ingrese una meta propuesta');
        return;
      }
      if (this.responsablesMetas.length <= 0) {
        await this.alertDialog('warning', 'Ingrese un responsable de meta');
        return;
      }
      if (this.frecuenciaMedicion.length <= 0) {
        await this.alertDialog('warning', 'Ingrese un frecuencia de medición');
        return;
      }
      if (this.responsableMedicion.length <= 0) {
        await this.alertDialog('warning', 'Ingrese un responsable de medición');
        return;
      }
      if (this.responsableEvalucion.length <= 0) {
        await this.alertDialog('warning', 'Ingrese un responsable de evaluación');
        return;
      }
      if (this.reviso.length <= 0) {
        await this.alertDialog('warning', 'Ingrese Revisó');
        return;
      }
      if (this.aprobo.length <= 0) {
        await this.alertDialog('warning', 'Ingrese Aprobó');
        return;
      }
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Desea guardar los cambios?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        const res = await this.fichaTecnicaService.post().execResource('guardar', {
          idempresa: decryptAES(localStorage.getItem('emp')),
          proceso: this.procesoEvaluado,
          nombre: this.nombreIndicador,
          quebusca: this.queBusca,
          unidmedida: this.unidadMedida,
          formacalculo: this.formaCalculo,
          detalleformula: this.detalleFormula,
          consideraciones: this.consideracion1 + '|' + this.consideracion2 + '|' + this.consideracion3,
          fuentes: this.fuenteDatos,
          historicos: this.datosHistoricos,
          meta: parseFloat(this.metaPropuesta),
          idresponsablemeta: this.responsablesMetas.code,
          frecuenciamedicion: this.frecuenciaMedicion,
          idresponsablemedicion: this.responsableMedicion.code,
          idresponsableevaluacion: this.responsableEvalucion.code,
          observaciones: this.observaciones,
          idresponsablerevision: this.reviso.code,
          idresponsableaprobacion: this.aprobo.code
        });
        this.showLoading = false;
        if (res.status) {
          await this.alertDialog('success', res.data);
          await this.$router.push({ path: '/indicadores/matriz' });
        } else {
          await this.alertDialog('error', res.data);
        }
      }
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    }
  },
  async mounted() {},
  async created() {
    this.fichaTecnicaService = this.$httpService(new FichaTecnicaService(), this);
    await this.cargarResponsables();
  }
};
</script>
