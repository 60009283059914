class FichaTecnicaService {
  constructor() {}
  resources = () => ({
    responsables: {
      uri: `/v1/responsables`,
      method: ['get']
    },
    guardar: {
      uri: `/v1/indicadores/hojanormalizacion`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    }
  });
}

export default FichaTecnicaService;
